import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_2b75056d from 'nuxt_plugin_plugin_2b75056d' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_c72d34aa from 'nuxt_plugin_cookieuniversalnuxt_c72d34aa' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginclient_7a275b2e from 'nuxt_plugin_pluginclient_7a275b2e' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_28a5c3f1 from 'nuxt_plugin_pluginserver_28a5c3f1' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_toast_b90b0e9c from 'nuxt_plugin_toast_b90b0e9c' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_516ba7fa from 'nuxt_plugin_axios_516ba7fa' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_i18n_66ff12a5 from 'nuxt_plugin_i18n_66ff12a5' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_device_bbe49f26 from 'nuxt_plugin_device_bbe49f26' // Source: ../plugins/device.js (mode: 'client')
import nuxt_plugin_siteType_24243cc2 from 'nuxt_plugin_siteType_24243cc2' // Source: ../plugins/siteType.js (mode: 'server')
import nuxt_plugin_language_6dfa112b from 'nuxt_plugin_language_6dfa112b' // Source: ../plugins/language.js (mode: 'server')
import nuxt_plugin_axios_397e53b5 from 'nuxt_plugin_axios_397e53b5' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_tooltip_d863c7f0 from 'nuxt_plugin_tooltip_d863c7f0' // Source: ../plugins/tooltip.js (mode: 'all')
import nuxt_plugin_slick_0bdbf753 from 'nuxt_plugin_slick_0bdbf753' // Source: ../plugins/slick.js (mode: 'all')
import nuxt_plugin_googleRemarketing_5e86d651 from 'nuxt_plugin_googleRemarketing_5e86d651' // Source: ../plugins/googleRemarketing.js (mode: 'all')
import nuxt_plugin_gtmclient_4b05506b from 'nuxt_plugin_gtmclient_4b05506b' // Source: ../plugins/gtm-client.js (mode: 'client')
import nuxt_plugin_gtagremarketingclient_7b7db268 from 'nuxt_plugin_gtagremarketingclient_7b7db268' // Source: ../plugins/gtag-remarketing-client.js (mode: 'client')
import nuxt_plugin_browser_6769b2c3 from 'nuxt_plugin_browser_6769b2c3' // Source: ../plugins/browser.js (mode: 'client')
import nuxt_plugin_entryId_0a71b2c4 from 'nuxt_plugin_entryId_0a71b2c4' // Source: ../plugins/entryId.js (mode: 'client')
import nuxt_plugin_primis_332f7633 from 'nuxt_plugin_primis_332f7633' // Source: ../plugins/primis.js (mode: 'client')
import nuxt_plugin_scrollLock_2288d7eb from 'nuxt_plugin_scrollLock_2288d7eb' // Source: ../plugins/scrollLock.js (mode: 'client')
import nuxt_plugin_textHighlight_47b1e6f8 from 'nuxt_plugin_textHighlight_47b1e6f8' // Source: ../plugins/textHighlight.js (mode: 'client')
import nuxt_plugin_facebookPixel_0293b88b from 'nuxt_plugin_facebookPixel_0293b88b' // Source: ../plugins/facebookPixel.js (mode: 'client')
import nuxt_plugin_microsoftUetq_456cb6f0 from 'nuxt_plugin_microsoftUetq_456cb6f0' // Source: ../plugins/microsoftUetq.js (mode: 'client')
import nuxt_plugin_adara_09fd057e from 'nuxt_plugin_adara_09fd057e' // Source: ../plugins/adara.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"travel-media-direct-app","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"## Front-end application"}],"link":[{"hid":"gf-prefetch","rel":"dns-prefetch","href":"https:\u002F\u002Ffonts.gstatic.com\u002F"},{"hid":"gf-preconnect","rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com\u002F","crossorigin":""},{"hid":"gf-preload","rel":"preload","as":"style","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Open+Sans:wght@300;400;500;600;700;800&family=Catamaran:wght@400;500;600;700;800&family=Mulish:wght@400;500;600;700;800&display=swap"}],"style":[],"script":[{"hid":"gf-script","innerHTML":"(function(){var l=document.createElement('link');l.rel=\"stylesheet\";l.href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Open+Sans:wght@300;400;500;600;700;800&family=Catamaran:wght@400;500;600;700;800&family=Mulish:wght@400;500;600;700;800&display=swap\";document.querySelector(\"head\").appendChild(l);})();"}],"noscript":[{"hid":"gf-noscript","innerHTML":"\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Open+Sans:wght@300;400;500;600;700;800&family=Catamaran:wght@400;500;600;700;800&family=Mulish:wght@400;500;600;700;800&display=swap\"\u003E"}],"__dangerouslyDisableSanitizersByTagID":{"gf-script":["innerHTML"],"gf-noscript":["innerHTML"]}},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2b75056d === 'function') {
    await nuxt_plugin_plugin_2b75056d(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_c72d34aa === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_c72d34aa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_7a275b2e === 'function') {
    await nuxt_plugin_pluginclient_7a275b2e(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_28a5c3f1 === 'function') {
    await nuxt_plugin_pluginserver_28a5c3f1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_b90b0e9c === 'function') {
    await nuxt_plugin_toast_b90b0e9c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_516ba7fa === 'function') {
    await nuxt_plugin_axios_516ba7fa(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_66ff12a5 === 'function') {
    await nuxt_plugin_i18n_66ff12a5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_device_bbe49f26 === 'function') {
    await nuxt_plugin_device_bbe49f26(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_siteType_24243cc2 === 'function') {
    await nuxt_plugin_siteType_24243cc2(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_language_6dfa112b === 'function') {
    await nuxt_plugin_language_6dfa112b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_397e53b5 === 'function') {
    await nuxt_plugin_axios_397e53b5(app.context, inject)
  }

  if (typeof nuxt_plugin_tooltip_d863c7f0 === 'function') {
    await nuxt_plugin_tooltip_d863c7f0(app.context, inject)
  }

  if (typeof nuxt_plugin_slick_0bdbf753 === 'function') {
    await nuxt_plugin_slick_0bdbf753(app.context, inject)
  }

  if (typeof nuxt_plugin_googleRemarketing_5e86d651 === 'function') {
    await nuxt_plugin_googleRemarketing_5e86d651(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmclient_4b05506b === 'function') {
    await nuxt_plugin_gtmclient_4b05506b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtagremarketingclient_7b7db268 === 'function') {
    await nuxt_plugin_gtagremarketingclient_7b7db268(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_browser_6769b2c3 === 'function') {
    await nuxt_plugin_browser_6769b2c3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_entryId_0a71b2c4 === 'function') {
    await nuxt_plugin_entryId_0a71b2c4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_primis_332f7633 === 'function') {
    await nuxt_plugin_primis_332f7633(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scrollLock_2288d7eb === 'function') {
    await nuxt_plugin_scrollLock_2288d7eb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_textHighlight_47b1e6f8 === 'function') {
    await nuxt_plugin_textHighlight_47b1e6f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_facebookPixel_0293b88b === 'function') {
    await nuxt_plugin_facebookPixel_0293b88b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_microsoftUetq_456cb6f0 === 'function') {
    await nuxt_plugin_microsoftUetq_456cb6f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_adara_09fd057e === 'function') {
    await nuxt_plugin_adara_09fd057e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
