import { render, staticRenderFns } from "./post.vue?vue&type=template&id=d06ffc10"
import script from "./post.vue?vue&type=script&lang=js"
export * from "./post.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/home/forge/dev.farescraper.com/components/AppHeader.vue').default,BlogPostShare: require('/home/forge/dev.farescraper.com/components/BlogPostShare.vue').default,BlogBreadcrumbs: require('/home/forge/dev.farescraper.com/components/BlogBreadcrumbs.vue').default,AppSearchForm: require('/home/forge/dev.farescraper.com/components/AppSearchForm.vue').default,BlogPostImage: require('/home/forge/dev.farescraper.com/components/BlogPostImage.vue').default,BlogAuthorSign: require('/home/forge/dev.farescraper.com/components/BlogAuthorSign.vue').default,GoogleAdSense: require('/home/forge/dev.farescraper.com/components/GoogleAdSense.vue').default,EzoicAds: require('/home/forge/dev.farescraper.com/components/EzoicAds.vue').default,BlogAuthorBio: require('/home/forge/dev.farescraper.com/components/BlogAuthorBio.vue').default,BlogFooter: require('/home/forge/dev.farescraper.com/components/BlogFooter.vue').default,BlogPost: require('/home/forge/dev.farescraper.com/components/BlogPost.vue').default,AppFooter: require('/home/forge/dev.farescraper.com/components/AppFooter.vue').default})
